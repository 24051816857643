import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import styles from './styles';
import sources from './sources';
import { humanNumber } from './humanNumber';
import layers from './layers';

// Necessary to wait for sources and styles to be loaded before initializing the store
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cant_access_lexical_declaration_before_init
function store() {
    const temp = Alpine.store('search');
    if (temp) {
        return temp;
    }

    const initialState = {
        mode: window.terraplat ? 'map' : 'both',
        sort: 'relevancy',
        query: '',
        state: [],
        type: [],
        status: window.property_statuses?.[0]?.slug,
        agents: [],
        price: { min: 0, max: 0 },
        size: { min: 0, max: 0 },
        limit: 16,
        page: 1,
        bbox: [],
        layers: Object.fromEntries(
            Object.values(layers).map((layer) => [
                layer.id,
                layer.layout.visibility === 'visibile',
            ]),
        ),
        style: 'hybrid',
    };

    Alpine.store('search', {
        loading: false,
        suggested: false,
        skipZoomEvents: false,

        styles,
        sources,
        layers,
        input: structuredClone(initialState),
        defaults: structuredClone(initialState),

        filter_labels: {
            query: 'Query',
            state: 'State',
            type: 'Type',
            status: 'Status',
            agents: 'Agent',
            price: 'Price',
            size: 'Acreage',
        },
        step: {
            price: 1000,
            size: 100,
        },
        sortByOptions: {
            relevancy: 'Sort by Relevancy',
            'list_date:desc': 'Date (Newest First)',
            'list_date:asc': 'Date (Oldest First)',
            'price:desc': 'Price (Highest First)',
            'price:asc': 'Price (Lowest First)',
            'size:desc': 'Acreage (Highest First)',
            'size:asc': 'Acreage (Lowest First)',
        },
        limitOptions: [
            { label: '8', value: 8 },
            { label: '16', value: 16 },
            { label: '24', value: 24 },
            { label: '32', value: 32 },
            { label: '40', value: 40 },
            { label: '48', value: 48 },
        ],

        processingTimeMs: 0,
        estimtedTotalHits: 0,
        facetStats: {},
        hitsUpdated: 0,
        get hits() {
            // hitsUpdated here is to trigger this computed property when the hits are updated
            this.hitsUpdated;

            if (this.estimatedTotalHits === 0) {
                return [];
            }

            return (window.hits ?? []).slice(
                (this.input.page - 1) * this.input.limit,
                this.input.page * this.input.limit,
            );
        },

        get priceLabel() {
            return `$${humanNumber(this.input.price.min)} - ${humanNumber(this.input.price.max)}`;
        },
        get sizeLabel() {
            return `${humanNumber(this.input.size.min)} - ${humanNumber(this.input.size.max)} ac`;
        },
        get typeLabel() {
            return this.input.type
                .map(
                    (input_type) =>
                        property_types.find(
                            (active_type) => active_type.slug === input_type,
                        )?.title,
                )
                .join(', ');
        },
        get statusLabel() {
            return property_statuses.find(
                (status) => status.slug === this.input.status,
            )?.title;
        },
        get statsLabel() {
            if (this.estimatedTotalHits === 0) {
                return 'No results found';
            }

            const start = (this.input.page - 1) * this.input.limit;
            const end = Math.min(
                this.input.page * this.input.limit,
                this.estimatedTotalHits,
            );
            return `Showing ${start}-${end} of ${this.estimatedTotalHits} results in ${this.processingTimeMs}ms`;
        },
    });

    window.store = Alpine.store('search');
    return window.store;
}

export default store;
export { store };
