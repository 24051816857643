import { updateLayers } from './layers';

function generateMapServerUrl(baseUrl, tiles = false, newParams = {}) {
    let params = {};
    if (tiles) {
        baseUrl += '/tile/{z}/{y}/{x}';
        params = {
            format: 'jpg',
        };
    } else {
        params = {
            bbox: '{bbox-epsg-3857}',
            bboxSR: '3857',
            imageSR: '3857',
            size: '512,512',
            dpi: '96',
            format: 'jpg',
            transparent: 'true',
            f: 'image',
        };
    }

    params = { ...params, ...newParams };
    const url = new URL(baseUrl);
    Object.entries(params).forEach(([key, param]) =>
        param !== null ? url.searchParams.append(key, param) : null,
    );

    return decodeURI(url.toString());
}

const sources = {
    properties: {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
        promoteId: 'id',
        // cluster: true,
        // clusterRadius: 35,
    },
    femaflood: {
        label: 'FEMA Flood',
        type: 'raster',
        tiles: [
            generateMapServerUrl(
                'https://hazards.fema.gov/gis/nfhl/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export',
                false,
                {
                    format: 'png32',
                    layers: 'show:19,20',
                    size: '256,256',
                },
            ),
        ],
        tileSize: 256,
        minzoom: 12,
        maxzoom: 18,
    },
    publiclands: {
        label: 'Public Lands',
        type: 'raster',
        tiles: [
            generateMapServerUrl(
                'https://gis1.usgs.gov/arcgis/rest/services/padus3/Manager_Name/MapServer',
                true,
                {
                    //
                },
            ),
        ],
        tileSize: 512,
        maxzoom: 13,
    },
    // waterfeatures imported from mapbox-streets
    wetlands: {
        label: 'Wetlands',
        type: 'raster',
        tiles: [
            generateMapServerUrl(
                'https://fwsprimary.wim.usgs.gov/server/rest/services/Test/Wetlands_gdb_split/MapServer/export',
                false,
                {
                    dpi: 96,
                    transparent: true,
                    format: 'png',
                },
            ),
            // generateMapServerUrl(
            //     'https://fwsprimary.wim.usgs.gov/server/rest/services/Wetlands/MapServer/export',
            //     false,
            //     {
            //         format: 'png32',
            //         layers: 'show:0,1',
            //     },
            // ),
        ],
        tileSize: 256,
        // tileSize: 512,
        minzoom: 12,
        maxzoom: 20,
    },
    soils: {
        label: 'Soils',
        type: 'raster',
        tiles: [
            generateMapServerUrl(
                // 'https://nrcsgeoservices.sc.egov.usda.gov/arcgis/rest/services/soils/soil_organic_carbon/MapServer/export', false, {
                //     bboxSR : '102100',
                //     imageSR: '102100',
                //     layers : 'show:0',
                // },
                // https://websoilsurvey.nrcs.usda.gov/
                // https://sdmdataaccess.sc.egov.usda.gov/WebServiceHelp.aspx#GetMap
                // https://www.nrcs.usda.gov/Internet/FSE_DOCUMENTS/nrcseprd1390056.html
                'https://SDMDataAccess.sc.egov.usda.gov/Spatial/SDM.wms',
                false,
                {
                    service: 'WMS',
                    version: '1.1.1',
                    request: 'GetMap',
                    layers: 'mapunitpolyextended,featline,featpoint',
                    // aoiid: '',
                    // interpresultid: '',
                    // sld_id: '',
                    // sld_body: '',
                    styles: '',
                    srs: 'EPSG:3857',
                    // bbox: '',
                    width: 512,
                    height: 512,
                    format: 'image/png',
                    // bgcolor: '0xFFFFFF',
                    // exceptions: 'XML',
                    //
                    transparent: true,
                    bboxSR: null,
                    imageSR: null,
                    size: null,
                    dpi: null,
                    f: null,
                },
            ),
        ],
        tileSize: 512,
        minzoom: 4,
        maxzoom: 13,
    },
};

function updateSources() {
    console.log('updateSources');

    Object.entries(sources).forEach(([key, source]) => {
        if (!window.mapboxMap.getSource(key)) {
            console.log(`Adding source ${key}`, { source });
            window.mapboxMap.addSource(key, source);
        }
    });

    window.mapboxMap.once('idle', () => updateLayers());
}

export default sources;
export { sources, updateSources, generateMapServerUrl };
