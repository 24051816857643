import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import { reCenterMap } from './map';
import sources from './sources';
import store from './store';

const layers = [
    {
        id: 'femaflood',
        label: 'FEMA Floodplains <a class="ml-1" href="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/legend" target="_blank" title="FEMA Floodplains Legend"><i class="fa fa-question-circle"></i></a>',
        type: 'raster',
        source: 'femaflood',
        layout: {
            visibility: 'none',
        },
    },
    {
        id: 'publiclands',
        label: 'Public Lands <a class="ml-1" href="https://gis1.usgs.gov/arcgis/rest/services/padus3/Manager_Name/MapServer/legend" target="_blank" title="Public Lands Legend"><i class="fa fa-question-circle"></i></a>',
        type: 'raster',
        source: 'publiclands',
        layout: {
            visibility: 'none',
        },
        paint: {
            'raster-opacity': 0.45,
        },
    },
    {
        label: 'Water Features',
        layout: {
            visibility: 'none',
        },
        layers: [
            {
                id: 'water',
                type: 'fill',
                source: 'composite',
                'source-layer': 'water',
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'fill-color': '#29e1fa',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: 'waterway',
                type: 'line',
                source: 'composite',
                'source-layer': 'waterway',
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'line-color': '#29e1fa',
                    'line-width': 5,
                    'line-opacity': 0.35,
                },
            },
        ],
    },
    {
        id: 'wetlands',
        label: 'Wet Lands',
        type: 'raster',
        source: 'wetlands',
        layout: {
            visibility: 'none',
        },
        paint: {
            'raster-opacity': 0.35,
        },
    },
    {
        id: 'soils',
        label: 'Soils',
        type: 'raster',
        source: 'soils',
        layout: {
            visibility: 'none',
        },
        paint: {
            'raster-opacity': 0.65,
        },
    },
];

function updateLayer(layer) {
    const input_id = layer.id;
    (layer.layers ?? [layer]).forEach(function (layer) {
        if (layer.source && !window.mapboxMap.getSource(layer.source)) {
            window.mapboxMap.addSource(layer.source, sources[layer.source]);
        }

        if (window.mapboxMap.getLayer(layer.id)) {
            window.mapboxMap.setLayoutProperty(
                layer.id,
                'visibility',
                window.store.input.layers[input_id] ? 'visible' : 'none',
            );
        } else {
            console.log(`Adding layer ${layer.id}`, { layer });
            layer.layout.visibility = window.store.input.layers[input_id]
                ? 'visible'
                : 'none';
            window.mapboxMap.addLayer(layer);
        }
    });
}

function updatePropertiesSource() {
    console.log('updatePropertiesSource', window.hits?.length);

    if (!window.hits?.length) {
        console.log('updateMapMarkers - no results, centering map');
        reCenterMap();
        return;
    }

    if (!window.mapboxMap.getSource('properties')) {
        window.mapboxMap.addSource('properties', sources.properties);
    }

    window.mapboxMap.getSource('properties').setData({
        type: 'FeatureCollection',
        features: window.hits
            .filter((hit) => hit._geo)
            .map((hit) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [hit._geo.lng, hit._geo.lat],
                },
                properties: {
                    id: hit.id,
                    url: hit.url,
                    image: hit.images[0],
                    price: hit.price,
                    size: hit.size,
                    county: hit.address.county.title,
                    county_type: hit.address.county.county_type,
                    state: hit.address.state.title,
                    type: hit.property_types[0].title,
                    type_slug: hit.property_types[0].slug,
                    status: hit.property_status.title,
                    status_slug: hit.property_status.slug,
                },
            })),
    });

    fitMapToProperties();
    loadImages();
}

function fitMapToProperties() {
    const coordinates = window.mapboxMap
        .getSource('properties')
        ._data.features.map((feature) => feature.geometry.coordinates);
    const bounds = coordinates.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
    );

    window.store.skipZoomEvents = true;
    window.mapboxMap.once(
        'moveend',
        () => (window.store.skipZoomEvents = false),
    );
    window.mapboxMap.fitBounds(bounds, {
        padding: 50,
        duration: 0,
        maxZoom: 7,
    });
}

async function loadImages() {
    const promises = property_types
        .filter((type) => !window.mapboxMap.getLayer(type.slug))
        .map((type) => [
            { icon: type.map_icon_for_sale, slug: `for-sale_${type.slug}` },
            {
                icon: type.map_icon_contract,
                slug: `contract_${type.slug}`,
            },
            { icon: type.map_icon_sold, slug: `sold_${type.slug}` },
        ])
        .flat()
        .map(
            (statusType) =>
                new Promise((resolve, reject) =>
                    window.mapboxMap.loadImage(
                        statusType.icon,
                        (error, data) => {
                            if (error) {
                                return reject(error);
                            }

                            if (!window.mapboxMap.hasImage(statusType.slug)) {
                                window.mapboxMap.addImage(
                                    statusType.slug,
                                    data,
                                );
                            }

                            resolve();
                        },
                    ),
                ),
        );

    await Promise.all(promises);

    if (window.mapboxMap.getLayer('properties')) {
        return;
    }

    if (!window.mapboxMap.getSource('properties')) {
        window.mapboxMap.addSource('properties', sources.properties);
    }

    window.mapboxMap.addLayer({
        id: 'properties',
        type: 'symbol',
        source: 'properties',
        layout: {
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-image': [
                'concat',
                ['get', 'status_slug'],
                '_',
                ['get', 'type_slug'],
            ],
        },
    });

    window.mapboxMap.on(
        'mouseenter',
        'properties',
        () => (window.mapboxMap.getCanvas().style.cursor = 'pointer'),
    );
    window.mapboxMap.on(
        'mouseleave',
        'properties',
        () => (window.mapboxMap.getCanvas().style.cursor = ''),
    );
    window.mapboxMap.on('click', 'properties', (e) => {
        const feature =
            window.mapboxMap.queryRenderedFeatures(e.point, {
                layers: ['properties'],
            })[0] ?? null;

        if (!feature) {
            return;
        }

        new mapboxgl.Popup()
            .setLngLat(feature.geometry.coordinates)
            .setHTML(
                `
                    <article class="flex flex-col rounded-xl bg-white p-3 drop-shadow-2xl shadow">
                        <header class="h-32 w-52 min-w-full rounded-t-xl border-b border-solid border-b-green pb-3">
                            <img
                                class="h-full w-full object-cover rounded-t-xl"
                                src="${feature.properties.image}"
                            />
                        </header>
                        <main class="space-y-1 p-2">
                            <div class="text-xs font-bold uppercase text-green">${feature.properties.state}</div>
                            <div class="text-lg text-green">${feature.properties.county} ${feature.properties.county_type}</div>
                        </main>
                        <footer class="flex flex-col rounded-b-lg border border-green">
                            <div class="flex basis-1/2 items-center border-b border-green">
                                <div class="basis-3/5 border-r border-green p-2 text-sm">
                                    Type<br>
                                    <span class="font-bold">${feature.properties.type}</span>
                                </div>
                                <div class="basis-2/5 p-2 text-sm">
                                    Acres<br>
                                    <span class="font-bold">${feature.properties.size}</span>
                                </div>
                            </div>
                            <div class="flex flex-grow basis-1/2 items-center justify-between px-2 py-1">
                                <span class="text-lg font-medium">$${feature.properties.price.toLocaleString()}</span>
                                <a
                                    href="${feature.properties.url}"
                                    class="cursor-pointer text-xs inline-flex items-center justify-center text-center no-underline font-semibold font-sans rounded-lg transition-colors w-max flex-wrap max-w-full py-2 text-white bg-red hocus:bg-red-hover px-3 uppercase"
                                    target="_blank"
                                >View</a>
                            </div>
                        </footer>
                    </article>
                `,
            )
            .addTo(window.mapboxMap);

        document.querySelector('.mapboxgl-popup-content').click();
    });
}

function updateLayers() {
    layers.forEach(updateLayer);
    updatePropertiesSource();
}

export default layers;
export { layers, updateLayers, fitMapToProperties, updatePropertiesSource };
