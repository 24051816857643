import {
    Livewire,
    Alpine,
} from '../../vendor/livewire/livewire/dist/livewire.esm';
import '../../vendor/aerni/livewire-forms/resources/dist/js/livewire-forms';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import resize from '@alpinejs/resize';
import tooltip from '@ryangjchandler/alpine-tooltip';
import Splide from '@splidejs/splide';
import './search/search';
import './settings';
import './favorite_properties';
import './fslightbox/fslightbox';

Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(resize);
Alpine.plugin(tooltip);
window.Alpine = Alpine;
window.Livewire = Livewire;
window.Splide = Splide;

window.scrollbarObserver = new ResizeObserver(() => {
    window.scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty(
        '--scrollbar-width',
        (scrollbarWidth > 100 ? 0 : scrollbarWidth) + 'px',
    );
}).observe(document.documentElement);
