// TODO: Remove this once import entries are validated
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import { updateLayers, updatePropertiesSource } from './layers.js';
import map from './map';
import store from './store.js';
import { updateMapStyle } from './styles.js';
import { initUrl, updateUrl } from './url';

// Meilisearch client and index
let client;
let index;

Alpine.data('search', function () {
    if (!window.meilisearch_host || !window.meilisearch_key) {
        return;
    }

    window.client = client = new Meilisearch({
        host: window.meilisearch_host,
        apiKey: window.meilisearch_key,
    });
    window.index = index = client.index('selandgroup');

    return {
        get store() {
            return window.store;
        },
        async init() {
            store();
            setupStats();
            initUrl();
            map();
            await search();

            Object.keys(window.store.input).forEach((key) =>
                this.$watch(`store.input.${key}`, () => inputUpdated(key)),
            );
        },

        windowResized() {
            const lg = 1024;
            if (window.innerWidth < lg && window.store.input.mode === 'both') {
                window.store.input.mode = 'list';
            }
        },
    };
});

const search = () =>
    new Promise((resolve, reject) => _deboucnedSearch(resolve, reject));
const _deboucnedSearch = Alpine.debounce(
    (resolve, reject) => _search().then(resolve).catch(reject),
    500,
);
async function _search() {
    if (window.store.loading) {
        return;
    }

    const filters = getFilters();
    window.store.loading = true;
    window.store.suggested = false;
    let results = await index.search(
        filters.selandgroup_id ? '' : window.store.input.query,
        {
            limit: 10000,
            offset: 0,
            facets: ['price', 'size', '_geo'],
            filter: Object.values(filters),
            sort: getSorts(),
        },
    );

    // Show suggested results from the same state or county or query if no results were found
    if (results.hits.length === 0) {
        window.store.suggested = true;
        results = await index.search(window.store.input.query, {
            limit: 10000,
            offset: 0,
            facets: ['price', 'size', '_geo'],
            filter: [
                filters.state ?? null,
                filters.county ?? null,
                filters.status,
            ].filter((v) => v),
            sort: getSorts(),
        });
    }

    window.hits = results.hits;
    window.store.processingTimeMs = results.processingTimeMs;
    window.store.estimatedTotalHits = results.estimatedTotalHits;
    window.store.hitsUpdated++;
    window.store.loading = false;
    console.log('search - done');
}

async function inputUpdated(key) {
    console.log('inputUpdated', key, Alpine.raw(window.store.input[key]));
    if (!window.terraplat) {
        window.results.scrollTop = 0;
    }

    if (key === 'mode' && window.store.input.mode !== 'list' && !map) {
        map();
        updateUrl();
        return;
    }

    if (key === 'style') {
        updateMapStyle();
        return;
    }

    if (key === 'layers') {
        updateLayers();
        return;
    }

    if (['page', 'limit', 'mode'].includes(key)) {
        if (key !== 'page' && window.store.input.page !== 1) {
            window.store.input.page = 1;
        }

        updateUrl();
        window.store.hitsUpdated++;
        return;
    }

    if (window.store.input.page !== 1) {
        window.store.input.page = 1;
        return;
    }

    await search();
    updateUrl();
    updatePropertiesSource();
}

function getFilters() {
    let filters = {};

    // TODO: Change this to CONTAINS when this operator is supporated - https://github.com/orgs/meilisearch/discussions/763
    // If the query is a number, filter by selandgroup_id only
    if (window.store.input.query?.match(/^\d+$/) !== null) {
        filters.selandgroup_id = `selandgroup_id = ${window.store.input.query}`;
        return filters;
    }

    if (window.store.input.agents.length) {
        filters.agents = `agents IN ['${window.store.input.agents.join("','")}']`;
        return filters;
    }

    // Filter results by map bounds
    // if (window.store.mode === 'both') {
    //     const ne = map().getBounds().getNorthEast();
    //     const sw = map().getBounds().getSouthWest();
    //     filters.mapbounds = `_geoBoundingBox([${ne.lat}, ${ne.lng}], [${sw.lat}, ${sw.lng}])`;
    // }

    if (window.store.input.state.length) {
        filters.state = `address.state.abbreviation IN ['${window.store.input.state.join("','")}']`;
    }

    if (window.store.input.type.length) {
        filters.type = `property_types.slug IN ['${window.store.input.type.join("','")}']`;
    }

    if (window.store.input.status) {
        filters.status = `property_status.slug = '${window.store.input.status}'`;
    }

    if (
        window.store.input.price.min !== window.store.defaults.price.min ||
        window.store.input.price.max !== window.store.defaults.price.max
    ) {
        filters.price = `price ${window.store.input.price.min} TO ${window.store.input.price.max}`;
    }

    if (
        window.store.input.size.min !== window.store.defaults.size.min ||
        window.store.input.size.max !== window.store.defaults.size.max
    ) {
        filters.size = `size ${window.store.input.size.min} TO ${window.store.input.size.max}`;
    }

    return filters;
}

function getSorts() {
    let sorts = [];

    if (window.store.input.sort !== 'relevancy') {
        sorts.push(window.store.input.sort);
    } else if (window.store.input.mode === 'both') {
        sorts.push(
            `_geoPoint(${window.mapboxMap.getCenter().lat}, ${window.mapboxMap.getCenter().lng}):asc`,
        );
    }

    return sorts;
}

async function setupStats() {
    window.store.facetStats = (
        await index.search('', {
            limit: 1,
            facets: ['price', 'size'],
        })
    ).facetStats;

    ['price', 'size'].forEach((facet) => {
        const currentDefault = window.store.defaults[facet];
        const step = window.store.step[facet] ?? 1;
        const facetStats = window.store.facetStats?.[facet];
        const newDefault = {
            min: Math.floor((facetStats?.min ?? 0) / step) * step + '',
            max: Math.ceil((facetStats?.max ?? 0) / step) * step + '',
        };

        if (JSON.stringify(currentDefault) === JSON.stringify(newDefault)) {
            return;
        }

        if (
            JSON.stringify(window.store.input[facet]) ===
            JSON.stringify(currentDefault)
        ) {
            window.store.input[facet] = newDefault;
        }

        window.store.defaults[facet] = newDefault;
    });
}

export default search;
export { search };
