import { generateMapServerUrl, updateSources } from './sources';
import store from './store';

const styles = {
    hybrid: {
        label: 'Hybrid',
        uri: 'mapbox://styles/mapbox/satellite-streets-v11',
    },
    satellite: {
        label: 'Satellite',
        uri: 'mapbox://styles/mapbox/satellite-v9',
    },
    roadmap: {
        label: 'Roadmap',
        uri: 'mapbox://styles/mapbox/streets-v11',
    },
    terrain: {
        label: 'Terrain',
        uri: 'mapbox://styles/selandgroup/cjqzj4a7x159l2ttdmqpjpexv',
    },
    usgstopo: {
        label: 'USGS Topo',
        version: 8,
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        sources: {
            usgstopo: {
                label: 'USGS Topo',
                type: 'raster',
                tiles: [
                    generateMapServerUrl(
                        'https://basemap().nationalmap().gov/ArcGIS/rest/services/USGSTopo/MapServer',
                        true,
                    ),
                ],
                tileSize: 256,
                maxzoom: 16,
            },
        },
        layers: [
            {
                id: 'usgstopo',
                type: 'raster',
                source: 'usgstopo',
                layout: {
                    visibility: 'visible',
                },
            },
        ],
    },
};

function updateMapStyle() {
    console.log('updateMapStyle');

    window.mapboxMap.setStyle(
        window.store.styles[window.store.input.style].uri ??
            window.store.styles[window.store.input.style],
    );

    window.mapboxMap.once('idle', () => updateSources());
}

export default styles;
export { styles, updateMapStyle };
