import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('favoriteProperty', function (id, favorited = null) {
    return {
        favorited,
        url: '',
        init() {
            this.$nextTick(async () => {
                this.url = this.$el.dataset.url;
                if (this.favorited === null) {
                    this.favorited = await (
                        await fetch(this.url, {
                            method: 'GET',
                            headers: { Accept: 'application/json' },
                        })
                    ).json();
                }
            });
        },
        tooltip() {
            return this.favorited
                ? 'Unfavorite this property'
                : 'Favorite this property';
        },
        async toggleFavorite($tooltip) {
            const old = this.favorited;
            this.favorited = !old;
            this.favorited = (
                await (
                    await fetch(this.url, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'X-CSRF-Token': window.livewireScriptConfig.csrf,
                        },
                    })
                ).json()
            ).includes(id);

            if (!this.favorited && window.location.pathname === '/favorites') {
                this.$root.closest('article').remove();
            } else {
                $tooltip(
                    this.favorited === old
                        ? 'Error'
                        : this.favorited
                          ? 'Saved'
                          : 'Removed',
                    { delay: 500 },
                );
            }
        },
    };
});
