export function humanNumber(number) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];

    for (let i = suffixes.length - 1; i >= 0; i--) {
        const unit = Math.pow(1000, i);

        if (number >= unit) {
            number = number / unit;

            if (number.toFixed(1).toString().length < 5) {
                number = number.toFixed(1);
            }

            return `${number}${suffixes[i]}`;
        }
    }

    return number;
}
