import { updateMapStyle } from './styles';
import store from './store';
import search from './search';

const center = { lng: -98.5795, lat: 39.8283 };
const zoom = 4;

function reCenterMap() {
    map().flyTo({ center, zoom, duration: 0 });
}

function mapDragged() {
    if (window.store.skipZoomEvents) {
        return;
    }

    window.store.input.page = 1;
    search();
}

function map() {
    if (window.mapboxMap) {
        return window.mapboxMap;
    }

    window.mapboxMap = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        container: window.map,
        style: window.store.styles[window.store.input.style].uri,
        center,
        zoom,
        interactive: true,
        scrollZoom: true,
        dragPan: true,
        dragRotate: true,
        keyboard: true,
        doubleClickZoom: true,
        touchZoomRotate: true,
    })
        .addControl(new mapboxgl.NavigationControl())
        .once('load', () => updateMapStyle())
        .on('dragend', () => mapDragged())
        .on('zoomend', () => mapDragged());

    // Update map width when mode changes
    new ResizeObserver((entries) =>
        entries[0].contentRect.width !== 0 ? window.mapboxMap.resize() : null,
    ).observe(window.map);

    return window.mapboxMap;
}

export default map;
export { map, reCenterMap };
