import { Alpine } from "../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.directive(
    'settings',
    (
        el,
        { value, modifiers, expression },
        { Alpine, effect, cleanup, evaluateLater },
    ) => {
        const $data = Alpine.$data(el);
        const expressionValue =
            expression === `x-settings:${value}`
                ? (callback) => callback(true)
                : evaluateLater(expression);

        if (value !== undefined && value in $data.settings) {
            effect(() => {
                expressionValue((newValue) => {
                    $data.settings[value] = newValue;
                });
            });
        }
    },
);
