import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import * as qs from 'neoqs';
import { parseValue } from 'query-types';
import store from './store';

function parse(input, defaults) {
    input = Alpine.raw(input);
    defaults = Alpine.raw(defaults);

    const values = qs.parse(decodeURI(location.search), {
        comma: true,
        allowDots: true,
        parseArrays: true,
        ignoreQueryPrefix: true,
        decoder: (value, defaultDecoder, charset, type) =>
            type === 'key'
                ? defaultDecoder(value)
                : parseValue(decodeURIComponent(value)),
    });

    Object.entries(values).forEach(([key, value]) => {
        if (Array.isArray(defaults?.[key]) && !Array.isArray(value)) {
            input[key] = [value];
            return;
        }

        if (typeof value === 'object' && value !== null) {
            input[key] = {
                ...defaults?.[key],
                ...value,
            };
            return;
        }

        input[key] = value;
    });

    return input;
}

function params(input, defaults) {
    input = Alpine.raw(input);
    defaults = Alpine.raw(defaults);

    return qs.stringify(input, {
        skipNulls: true,
        allowDots: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        filter: (prefix, value) => {
            const defaultValue = prefix
                .split('.')
                .reduce((a, b) => a?.[b] ?? null, defaults);

            if (
                value === '' ||
                JSON.stringify(defaultValue) === JSON.stringify(value)
            ) {
                return;
            }

            return value;
        },
    });
}

function updateInput() {
    window.store.input = {
        ...window.store.input,
        ...parse(window.store.input, window.store.defaults),
    };
}

const updateUrl = Alpine.debounce(() => _updateUrl(), 500);
function _updateUrl() {
    history.pushState(
        {},
        '',
        params(window.store.input, window.store.defaults) || location.pathname,
    );
}

function initUrl() {
    updateInput();
    addEventListener('popstate', updateInput);
}

export { initUrl, updateUrl };
